import React, { useState } from "react";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import {
  Form,
  Row,
  Col,
  Select,
  Space,
  Button,
  Drawer,
  InputNumber,
  message,
} from "antd";

import { Component } from "../../utils/types/component";
import { useAppDispatch } from "../../redux/hooks";
import { updateSupplierCompliances } from "../../redux/actions/supplierPortalAction";
import FileUploader from "../layouts/FileUploader";
import { getLocalStorage } from "../../utils/localStore";

// FMD TOUR GUIDE 
// import { Tour } from "antd";
// import type { TourProps } from "antd";
// import { useRef } from "react";

const MaterialForm: React.FC<{
  type: string;
  fetchData: any;
  formData?: Component;
  campaignId?: any;
  show?: any;
  setShow?: any;
  showTourGuide?: boolean;
  inSupplierPortal?: boolean;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { tenantNumber } = getLocalStorage("user");
  const [isUploading, setIsUploading] = useState(false);

  // FMD TOUR GUIDE
  // const editButtonRef = useRef(null);
  // const partWeightUnitRef = useRef(null);
  // const partWeightRef = useRef(null);
  // const uploadRef = useRef(null);
  // const submitRef = useRef(null);
  // const steps: TourProps["steps"] = [
  //   {
  //     title: "Click On The Edit Compliance Button.",
  //     description: "1/10",
  //     // cover: <img alt="" src={Compliance3} />,
  //     target: () => editButtonRef.current,
  //     placement: "right",
  //     nextButtonProps: { children: "Next", onClick: () => setOpen(true) },
  //   },
  //   {
  //     title: "This Edit Compliance form will pop up on the screen",
  //     description: "2/10",
  //     // cover: <img alt="" src={Compliance4} />,
  //   },
  //   {
  //     title: "Click On The Edit Compliance Button.",
  //     description: "3/10",
  //     // cover: <img alt="" src={Compliance3} />,
  //     target: () => partWeightRef.current,
  //     placement: "right",
  //     prevButtonProps: { children: "Previous", onClick: () => setOpen(false) },
  //   },
  //   {
  //     title: "Click On The Edit Compliance Button.",
  //     description: "3/10",
  //     // cover: <img alt="" src={Compliance3} />,
  //     target: () => partWeightUnitRef.current,
  //     placement: "right",
  //   },
  //   {
  //     title: "Click On The Edit Compliance Button.",
  //     description: "3/10",
  //     // cover: <img alt="" src={Compliance3} />,
  //     target: () => uploadRef.current,
  //     placement: "right",
  //   },
  //   {
  //     title: "Click On The Edit Compliance Button.",
  //     description: "3/10",
  //     // cover: <img alt="" src={Compliance3} />,
  //     target: () => submitRef.current,
  //     placement: "bottomRight",
  //   },
  //   {
  //     title: "Click On The Edit Compliance Button.",
  //     description: "3/10",
  //     // cover: <img alt="" src={Compliance3} />,
  //     placement: "center",
  //     nextButtonProps: { children: "Finish", onClick: () => setOpen(false) },
  //   },

  // ];

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const onFormSubmit = (values: Component) => {
    setLoading(true);
    const isFormEmpty = Object.values(values).every(
      (field) => field === undefined || field === ""
    );
    if (isFormEmpty) {
      message.error("Please fill at least one field!");
      setLoading(false);
      return;
    }
    if (props.type === "create") {
      //
    } else if (props.formData) {
      dispatch(
        updateSupplierCompliances({
          updates: {
            ...values,
          },
          componentId: [props.formData._id],
          campaignId: props.campaignId,
          dates: getLocalStorage("expireAt"),
          tenantNumber: tenantNumber,
          updateType: "material disclosure",
        })
      )
        .then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
          onClose();
        })
        .catch(() => setLoading(false));
    }
  };

  return (
    <div>
      {/* FMD TOUR GUIDE */}
      {/* {props.showTourGuide && <Tour
        open={props.show}
        onClose={() => props.setShow(false)}
        steps={steps}
        disabledInteraction={true}
      />} */}
      <Button
        type={props.type === "create" ? "primary" : "text"}
        onClick={showDrawer}
        icon={props.type === "create" ? <PlusOutlined /> : <EditOutlined />}
        // ref={editButtonRef}
      >
        {props.type === "create" ? "Add Component" : null}
      </Button>
      <Drawer
        title={props.type === "create" ? "Add Component" : "Edit Component"}
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={form.submit}
              type="primary"
              loading={loading}
              // ref={submitRef}
              disabled={isUploading}
            >
              Submit
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={props.type === "create" ? {} : { ...props.formData }}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Row gutter={16}>
            {/* FMD TOUR GUIDE - ADD REF TO COLUMN REF   */}
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="partWeight"
                label="Part Weight"
                rules={[
                  {
                    required: props.inSupplierPortal ? false : true,
                    message: "Please input part weight!",
                  },
                  {
                    pattern: /^([0-9.])+$/,
                    message: "Please enter only numeric characters.",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Part Weight"
                  style={{ width: 324 }}
                  min={0}
                />
              </Form.Item>
            </Col>
            {/* FMD TOUR GUIDE - ADD REF TO COLUMN REF   */}
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="partWeightUnit"
                label="Part Weight Unit"
                rules={[
                  {
                    required: props.inSupplierPortal ? false : true,
                    whitespace: true,
                    message: "Please input part weight unit!",
                  },
                ]}
              >
                <Select
                  options={[
                    { label: "kg", value: "kg" },
                    { label: "g", value: "g" },
                    { label: "mg", value: "mg" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="fmdDocument" label="FMD Document" rules={[]}>
                {/* <span ref={uploadRef}> */}
                <FileUploader
                  postUrl="public/suppliers/document/upload"
                  tenantNumber={tenantNumber}
                  additionalData={{
                    campaignId: props.campaignId,
                    foldername: "campaign",
                    subfolder: "materials",
                  }}
                  setFormField={(value: string) =>
                    form.setFieldValue("fmdDocument", value)
                  }
                  formField={props.formData?.fmdDocument}
                  onUploadStatusChange={setIsUploading}
                />
                {/* </span> */}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
};

export default MaterialForm;
