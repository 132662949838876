import React, { useState } from "react";
import {
  PlusOutlined,
  FileDoneOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  Select,
  Row,
  Space,
  Steps,
  Result,
  AutoComplete,
} from "antd";

import { useAppDispatch } from "../../redux/hooks";

import { createExportRecord } from "../../redux/actions/exportDataAction";
import { listProducts } from "../../redux/actions/productsAction";
import {
  getLocalStorage,
  getRegulationsFromLocalStorage,
} from "../../utils/localStore";

const { Option } = Select;

const ExportDataForm: React.FC<{
  fetchData: any;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const { modules } = getLocalStorage("user");
  const regulations = getRegulationsFromLocalStorage() ?? [];
  const [searchProduct, setSearchProduct] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [refreshState, setRefreshState] = useState("");
  const [form] = Form.useForm();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setCurrentStep(0);
    setOpen(false);
    setRefreshState("");
    form.resetFields();
    props.fetchData();
  };

  const onFormSubmit = (values: any) => {
    setLoading(true);
    dispatch(
      createExportRecord({
        exportType: values.exportType.split(" ")[1],
        exportTypeData: values.exportType.split(" ")[0],
        exportFilters: {
          productId: values.productId
            ? searchProduct.find((e) => e.value === values?.productId)?._id
            : null,
        },
      })
    ).then(() => {
      setLoading(false);
    });
    setCurrentStep(1);
  };

  const handleProductSearch = (search?: any) => {
    dispatch(
      listProducts({
        search: true,
        pageNum: 1,
        pageSize: 10,
        searchField: search?.length ? search : "",
        softDelete: false,
        filters: encodeURIComponent(
          JSON.stringify({
            $or: [
              { number: { $regex: search, $options: "i" } },
              { name: { $regex: search, $options: "i" } },
            ],
          })
        ),
      })
    )
      .then((response: any) => {
        setSearchProduct(
          response.products.map((e: any) => ({
            value: e.name,
            ...e,
          }))
        );
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <Button
        type="primary"
        title="Export Data"
        onClick={showDrawer}
        icon={<PlusOutlined />}
      >
        Export Data
      </Button>
      <Drawer
        title="Export Data"
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose}>
              {currentStep === 1 ? "Close" : "Cancel"}
            </Button>
            {currentStep === 0 && (
              <Button onClick={form.submit} type="primary" loading={loading}>
                Start Export
              </Button>
            )}
          </Space>
        }
      >
        <Space direction="vertical" size="large" style={{ display: "flex" }}>
          <Steps
            items={[
              {
                title: "Export",
                status: currentStep < 0 ? "wait" : "finish",
                icon: <DownloadOutlined />,
              },
              {
                title: "Done",
                status: currentStep < 1 ? "wait" : "finish",
                icon: <FileDoneOutlined />,
              },
            ]}
          />
          {currentStep === 0 ? (
            <Form form={form} layout="vertical" onFinish={onFormSubmit}>
              <Row gutter={16} align="middle">
                <Col span={18}>
                  <Form.Item
                    name="exportType"
                    label="Type of Data"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please select the type of data",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select the type of data"
                      onChange={(value) => {
                        setRefreshState(value);
                        if (value === "BillOfMaterials") {
                          handleProductSearch();
                        }
                      }}
                    >
                      {modules &&
                        modules.find(
                          (e: any) =>
                            e.number === "products" || e.number === "components"
                        ) && (
                          <Option value="BillOfMaterials" key="BillOfMaterials">
                            Bill Of Materials
                          </Option>
                        )}
                      {modules &&
                        modules.find(
                          (e: any) =>
                            e.number === "products" || e.number === "components"
                        ) &&
                        regulations.length &&
                        regulations.map((regulation: any) => (
                          <Option
                            value={regulation.regulationNumber + " Components"}
                            key={regulation.regulationNumber}
                          >
                            {regulation.name} Compliances
                          </Option>
                        ))}
                      {/* {modules &&
                        modules.find((e: any) => e.number === "materials") && (
                          <Option value="Materials">
                            Full Material Disclosure
                          </Option>
                        )} */}
                      {/* {modules &&
                        modules.find((e: any) => e.number === "suppliers") && (
                          <Option value="Suppliers">Suppliers</Option>
                        )} */}
                      {modules &&
                        modules.find(
                          (e: any) => e.number === "conflictminerals"
                        ) && (
                          <>
                            <Option value="ConflictMinerals Smelters">
                              Conflict Minerals Smelters
                            </Option>
                            <Option value="ConflictMinerals Suppliers">
                              Conflict Minerals Suppliers
                            </Option>
                          </>
                        )}
                      {modules &&
                        modules.find(
                          (e: any) => e.number === "extendedminerals"
                        ) && (
                          <>
                            <Option value="ExtendedMinerals Smelters">
                              Extended Minerals Smelters
                            </Option>
                            <Option value="ExtendedMinerals Suppliers">
                              Extended Minerals Suppliers
                            </Option>
                          </>
                        )}
                    </Select>
                  </Form.Item>
                </Col>
                {form.getFieldValue("exportType") === "BillOfMaterials" && (
                  <Col span={18}>
                    <Form.Item
                      name="productId"
                      label="Product"
                      extra="Please select a product to extract bill of materials"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please select the product reference",
                        },
                      ]}
                    >
                      <AutoComplete
                        options={searchProduct.map((option) => ({
                          ...option,
                          label: (
                            <>
                              <div>{option?.name} </div>
                              <div> {option?.number} </div>
                            </>
                          ),
                        }))}
                        onSearch={handleProductSearch}
                        placeholder="Product Reference"
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                )}
                {/* <Col span={18}>
                  <Form.Item label="Li">

                  </Form.Item>
                </Col> */}
              </Row>
            </Form>
          ) : null}

          {currentStep === 1 ? (
            <Result
              status="info"
              title="We are currently processing your data."
              subTitle="The export will take 3-15 mins time to complete depending on the size of the data. You can check the status of the Export in the Export section."
            />
          ) : null}
        </Space>
      </Drawer>
    </>
  );
};

export default ExportDataForm;
