import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  EditOutlined,
  CloseOutlined,
  CheckCircleFilled,
  ExclamationCircleFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";

import {
  Form,
  Input,
  Row,
  Col,
  Space,
  Button,
  Drawer,
  Select,
  Typography,
  Tabs,
  Checkbox,
  Divider,
  Card,
  Spin,
  Steps,
  InputNumber,
  Collapse,
  Popconfirm,
  Alert,
} from "antd";
import type { TabsProps, CollapseProps, CheckboxProps } from "antd";
import { useAppDispatch } from "../../../../../redux/hooks";
import {
  readScipProductArticle,
  readScipArticle,
  createScipArticle,
  createDossier,
} from "../../../../../redux/actions/scipAction";
import {
  getLocalStorage,
  getRegulationsFromLocalStorage,
} from "../../../../../utils/localStore";
import {
  PrimaryArticleIdentifierTypeList,
  ArticleCategoryList,
  EUProductionFlagList,
  unit,
  densityUnit,
  weightUnit,
  volumeUnit,
  color,
} from "../../../../../utils/constants/scip";
import { Scip } from "../../../../../utils/types/scip";
import FileUploader from "../../../../../components/layouts/FileUploader";
import ScipForm from "../../../../../components/forms/ScipForm";
import DossierValidation from "../../../../../components/modals/SCIP/DossierValidation";

const { Title, Text } = Typography;
const { TextArea } = Input;

const ScipCreate: React.FC = () => {
  const [form] = Form.useForm();
  const { productId } = useParams();
  const dispatch = useAppDispatch();
  const [scipProductArticle, setScipProductArticle] = useState<any>();
  const [scipArticle, setScipArticle] = useState<any[]>([]);
  const [identifiersIcon, setIdentifiersIcon] = useState<string | boolean>("");
  const [instructionIcon, setInstructionIcon] = useState<string | boolean>("");
  const [complexIcon, setComplexIcon] = useState<string | boolean>("");
  const [checked, setChecked] = useState(false);
  const { tenantNumber } = getLocalStorage("user");
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const navigate = useNavigate();
  const [failStatus, setFailStatus] = useState<boolean>(false);
  const [primaryArticleIdentifierType, setPrimaryArticleIdentifierType] =
    useState<string>("");
  const [versionModified, setVersionModified] = useState(false);
  const regulations = getRegulationsFromLocalStorage() ?? [];

  const configuration = regulations
    .find((e: any) => e.regulationNumber === "eu_reach")
    ?.configuration.find((v: any) => v.value === "complianceOutputs.version");

  const getCurrentDate = (): string => {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    return date;
  };

  const currentDate = getCurrentDate();

  const onChangeCheck: CheckboxProps["onChange"] = (e) => {
    setChecked(e.target.checked);
    form.setFieldValue("SafeUseInstructions", []);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const onCancel = () => {
    navigate(-1);
  };

  const onSaveArticle = async () => {
    try {
      if (currentStep === 0) {
        await form.validateFields([
          ["ArticleName"],
          ["PrimaryArticleIdentifierType"],
          ["PrimaryArticleIdentifierValue"],
          primaryArticleIdentifierType !== "66297" && ["ArticleCategory"],
          primaryArticleIdentifierType !== "66297" && ["EUProductionFlag"],
        ]);
        setIdentifiersIcon(false);
        onSubmit();
      } else if (currentStep === 1) {
        await form.validateFields([
          ["NoSafeUseInstructions"],
          ["SafeUseInstructions"],
        ]);
        const values = form.getFieldValue("SafeUseInstructions") || [];
        for (let index = 0; index <= values?.length; index++) {
          await form.validateFields([["SafeUseInstructions", index]]);
        }
        setInstructionIcon(false);
        onSubmit();
      } else if (currentStep === 2) {
        await form.validateFields([["ComplexObjectComponents"]]);
        await form.validateFields();
        setComplexIcon(false);
        onSubmit();
      }
    } catch (error: any) {
      if (error.errorFields.length > 0) {
        const identifier = [
          "ArticleName",
          "PrimaryArticleIdentifierType",
          "PrimaryArticleIdentifierValue",
          primaryArticleIdentifierType !== "66297" && "ArticleCategory",
          primaryArticleIdentifierType !== "66297" && "EUProductionFlag",
        ];
        if (
          error.errorFields.some((e: any) =>
            identifier.some((o) => e.name.includes(o))
          )
        ) {
          setIdentifiersIcon(true);
        }

        const instructions = ["NoSafeUseInstructions", "SafeUseInstructions"];
        if (
          error.errorFields.some((e: any) =>
            instructions.some((o) => e.name.includes(o))
          )
        ) {
          setInstructionIcon(true);
        }
        if (
          error.errorFields.some((e: any) =>
            e.name.includes("ComplexObjectComponents")
          )
        ) {
          setComplexIcon(true);
        }
      }
    }
  };

  const onSubmit = () => {
    setLoading(true);
    const values = form.getFieldsValue();
    const { version, ...restValues } = values;
    const articles = {
      componentId: productId,
      type: "products",
      currentStep: currentStep,
      ...restValues,
      ...(versionModified && { version }),
      PrimaryArticleIdentifierType: PrimaryArticleIdentifierTypeList.find(
        (e: any) => e.code === values.PrimaryArticleIdentifierType
      ),
      ArticleCategory: values?.ArticleCategory?.map((value: any) =>
        ArticleCategoryList.find((e: any) => e.code === value)
      ),
      EUProductionFlag: EUProductionFlagList.find(
        (e: any) => e.code === values.EUProductionFlag
      ),
      Characteristics: {
        ...values.Characteristics,
        HeightUnit: unit.find(
          (e: any) => e.code === values.Characteristics?.HeightUnit
        ),
        WeightUnit: weightUnit.find(
          (e: any) => e.code === values.Characteristics?.WeightUnit
        ),
        VolumeUnit: volumeUnit.find(
          (e: any) => e.code === values.Characteristics?.VolumeUnit
        ),
        DensityUnit: densityUnit.find(
          (e: any) => e.code === values.Characteristics?.DensityUnit
        ),
        Color: color.find((e: any) => e.code === values.Characteristics?.Color),
      },
      ComplexObjectComponents: values.ComplexObjectComponents?.map(
        (e: any) => ({
          ArticleLink: {
            ArticleName: e.ArticleName,
            uuid: e.uuid,
          },
          NumberOfUnits: {
            lowerBoundQualifiers: e.NumberOfUnits?.lowerBoundQualifiers,
            lowerBoundValue: e.NumberOfUnits?.lowerBoundValue,
            upperBoundQualifiers: e.NumberOfUnits?.upperBoundQualifiers,
            upperBoundValue: e.NumberOfUnits?.upperBoundValue,
          },
        })
      ),
    };
    setCurrentStep(currentStep + 1);
    dispatch(createScipArticle(articles)).then(() => {
      setLoading(false);
      setCurrentStep(currentStep + 1);
      setPrimaryArticleIdentifierType("");
    });
  };

  const onFinish = () => {
    setLoading(true);
    dispatch(
      createDossier({ ArticleName: form.getFieldValue("ArticleName") })
    ).then(() => {
      form.resetFields();
      onCancel();
      setLoading(false);
    });
  };

  const handleFailCounts = (value: any) => {
    setFailStatus(value);
  };

  const actionCards = [
    primaryArticleIdentifierType !== "66297" ? (
      <Space key="1" style={{ display: "flex", justifyContent: "end" }}>
        {currentStep > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={prevStep}>
            Previous
          </Button>
        )}
        {currentStep <= 4 && (
          <Button
            style={{ background: "#045B7C", color: "white", marginRight: 8 }}
            type="text"
            onClick={onCancel}
          >
            {currentStep === 3 ? "Exit Page" : "Cancel"}
          </Button>
        )}
        {currentStep <= 2 && (
          <Button onClick={onSaveArticle} loading={loading} type="primary">
            Save & Continue
          </Button>
        )}
        {currentStep === 3 && (
          <DossierValidation
            productId={productId}
            failCounts={handleFailCounts}
          />
        )}
        {currentStep === 3 && failStatus && (
          <Button onClick={onFinish} loading={loading} type="primary">
            Create Dossier
          </Button>
        )}
      </Space>
    ) : (
      <Space key="1" style={{ display: "flex", justifyContent: "end" }}>
        {currentStep > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={prevStep}>
            Previous
          </Button>
        )}
        {currentStep <= 1 && (
          <Button
            style={{ background: "#045B7C", color: "white", marginRight: 8 }}
            type="text"
            onClick={onCancel}
          >
            {currentStep === 3 ? "Exit Page" : "Cancel"}
          </Button>
        )}
        {currentStep <= 0 && (
          <Button onClick={onSaveArticle} loading={loading} type="primary">
            Save & Continue
          </Button>
        )}
        {currentStep === 1 && (
          <DossierValidation
            productId={productId}
            failCounts={handleFailCounts}
          />
        )}
        {currentStep === 1 && failStatus && (
          <Button onClick={onFinish} loading={loading} type="primary">
            Create Dossier
          </Button>
        )}
      </Space>
    ),
  ];

  const characteristicsItem: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <>
          <Text>Characteristics (Optional) </Text>
        </>
      ),
      children: (
        <>
          <Row gutter={24}>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name={["Characteristics", "Height"]}
                label="Height"
                rules={[]}
              >
                <InputNumber style={{ width: 424 }} min={0} />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name={["Characteristics", "HeightUnit"]}
                label="Height Unit"
                rules={[]}
              >
                <Select
                  options={unit.map((option) => ({
                    label: <Text>{option.text}</Text>,
                    value: option.code,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name={["Characteristics", "Weight"]}
                label="Weight"
                rules={[]}
              >
                <InputNumber style={{ width: 424 }} min={0} />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name={["Characteristics", "WeightUnit"]}
                label="Weight Unit"
                rules={[]}
              >
                <Select
                  options={weightUnit.map((option) => ({
                    label: <Text>{option.text}</Text>,
                    value: option.code,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name={["Characteristics", "Volume"]}
                label="Volume"
                rules={[]}
              >
                <InputNumber style={{ width: 424 }} min={0} />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name={["Characteristics", "VolumeUnit"]}
                label="Volume Unit"
                rules={[]}
              >
                <Select
                  options={volumeUnit.map((option) => ({
                    label: <Text>{option.text}</Text>,
                    value: option.code,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name={["Characteristics", "Density"]}
                label="Density"
                rules={[]}
              >
                <InputNumber style={{ width: 424 }} min={0} />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name={["Characteristics", "DensityUnit"]}
                label="Density Unit"
                rules={[]}
              >
                <Select
                  options={densityUnit.map((option) => ({
                    label: <Text>{option.text}</Text>,
                    value: option.code,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name={["Characteristics", "Color"]}
                label="Color"
                rules={[]}
              >
                <Select
                  options={color.map((option) => ({
                    label: <Text>{option.text}</Text>,
                    value: option.code,
                  }))}
                  mode="multiple"
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name={["Characteristics", "pictureImage"]}
                label="Picture Image Upload"
                rules={[]}
              >
                <FileUploader
                  postUrl="scip/document/upload"
                  tenantNumber={tenantNumber}
                  setFormField={(value: string) =>
                    form.setFieldValue(
                      ["Characteristics", "pictureImage"],
                      value
                    )
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  const items: TabsProps["items"] = [
    {
      key: "0",
      label: (
        <>
          Identifiers
          <span style={{ marginLeft: "8px" }}>
            {identifiersIcon === true ? (
              <ExclamationCircleFilled
                style={{ color: "#F5222D", fontSize: "16px" }}
              />
            ) : identifiersIcon === false ? (
              <CheckCircleFilled
                style={{ color: "#52C41A", fontSize: "16px" }}
              />
            ) : (
              ""
            )}
          </span>
        </>
      ),
      children: (
        <>
          {/* <Title level={5}>Identifiers</Title>
          <Divider /> */}
          <Row gutter={24}>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="name" label="Product Name">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="number" label="Product Number">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="version" label="EU REACH SVHC Version">
                <Select
                  placeholder={`Select ${configuration.text}`}
                  options={configuration.dataValue
                    .split(",")
                    .map((value: string) => ({ label: value, value }))}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="ArticleName"
                label="Article Name"
                rules={[
                  {
                    required: true,
                    message: "Please input Article Name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="PrimaryArticleIdentifierType"
                label="Article Identifier (Type)"
                rules={[
                  {
                    required: true,
                    message: "Please Select Article Identifier (Type)!",
                  },
                ]}
              >
                <Select
                  options={PrimaryArticleIdentifierTypeList.map((option) => ({
                    label: <Text>{option.text}</Text>,
                    value: option.code,
                  }))}
                  onChange={(value) => {
                    setPrimaryArticleIdentifierType(value);
                    if (value !== "66306") {
                      form.setFieldValue("PrimaryArticleIdentifierValue", "");
                    } else {
                      form.setFieldValue(
                        "PrimaryArticleIdentifierValue",
                        scipProductArticle?.PrimaryArticleIdentifierValue
                          ? scipProductArticle?.PrimaryArticleIdentifierValue
                          : scipProductArticle?.manufacturer?.itemNumber
                      );
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="PrimaryArticleIdentifierValue"
                label="Article Identifier (Value)"
                rules={[
                  {
                    required: true,
                    message: "Please enter Article Identifier (Value)!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {/* <Title level={5}>Categorisation</Title>
          <Divider /> */}
          {primaryArticleIdentifierType !== "66297" && (
            <Row gutter={24}>
              <Col span={12} sm={12} md={12}>
                <Form.Item
                  name="ArticleCategory"
                  label="Article Category"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Article Category!",
                    },
                  ]}
                >
                  <Select
                    options={ArticleCategoryList.map((option) => ({
                      label: (
                        <Text
                          ellipsis={{
                            tooltip: option.text,
                          }}
                        >
                          {option.text}
                        </Text>
                      ),
                      value: option.code,
                    }))}
                    mode="multiple"
                  />
                </Form.Item>
              </Col>
              <Col span={12} sm={12} md={12}>
                <Form.Item
                  name="EUProductionFlag"
                  label="Production in European Union"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Production in European Union!",
                    },
                  ]}
                >
                  <Select
                    options={EUProductionFlagList.map((option) => ({
                      label: <Text>{option.text}</Text>,
                      value: option.code,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Col>
            {primaryArticleIdentifierType !== "66297" && (
              <Collapse items={characteristicsItem} bordered={false} />
            )}
          </Col>
        </>
      ),
      disabled: currentStep < 0,
    },
    {
      key: primaryArticleIdentifierType !== "66297" ? "3" : "1",
      label: "Preview Declaration",
      children: (
        <>
          <Card title={`Article Name ${form.getFieldValue("ArticleName")}`}>
            <Text>UUID: {form.getFieldValue("DossierUuid") || "NA"} </Text>
            <br></br>
            <Text>Author: Acquis Compliance</Text>
            <br></br>
            <Text>Date: {currentDate}</Text>
            <br></br>
            <Divider />
            <Title level={5}>Identifiers</Title>
            <Text> Article Name : {form.getFieldValue("ArticleName")}</Text>
            <br></br>
            <Text>
              Primary ArticleIdentifier (Type ):{" "}
              {form.getFieldValue("PrimaryArticleIdentifierType")}
            </Text>
            <br></br>
            <Text>
              Primary ArticleIdentifier (Value) :{" "}
              {form.getFieldValue("PrimaryArticleIdentifierValue")}
            </Text>
            <br></br>
            <Divider />
            {primaryArticleIdentifierType !== "66297" && (
              <div>
                <Title level={5}> Categorisation </Title>
                <Text>
                  Article category :{" "}
                  {form.getFieldValue("ArticleCategory")?.toString()}
                </Text>
                <br></br>
                <Text>
                  Production in European Union:{" "}
                  {form.getFieldValue("EUProductionFlag")}
                </Text>
                <br></br>
                <Divider />
                <Title level={5}> Safe Use Instructions </Title>
                <Text>
                  The identification of the Candidate List substance is
                  sufficient to allow safe use of the article throughout the
                  whole life cycle including service life, disassembly and
                  waste/recycling stage :
                  {form.getFieldValue("NoSafeUseInstructions")?.toString()}
                </Text>
                {form.getFieldValue("SafeUseInstructions")?.length > 0 && (
                  <Text>{form.getFieldValue("SafeUseInstructions")}</Text>
                )}
                <br></br>
                <Divider />
                {form.getFieldValue("Characteristics") !== undefined &&
                  Object.keys(form.getFieldValue("Characteristics"))?.length >
                    0 && (
                    <>
                      <Title level={5}> Characteristics</Title>
                      {Object.entries(
                        form.getFieldValue("Characteristics")
                      ).map(([key, value]) => (
                        <div key={key}>
                          <Text>
                            {key}:
                            {typeof value === "object" && value !== null
                              ? "text" in value
                                ? (value as { text: string })?.text
                                : JSON.stringify(value)
                              : value?.toString()}
                          </Text>
                        </div>
                      ))}
                      <Divider />
                    </>
                  )}
                <Title level={5}> Complex Object Components </Title>
                {form.getFieldValue("ComplexObjectComponents")?.length &&
                  form
                    .getFieldValue("ComplexObjectComponents")
                    ?.map((e: any, i: any) => (
                      <>
                        <Col span={24} key={i}>
                          <Text>
                            Article Name :
                            {e.ArticleLink?.ArticleName
                              ? e.ArticleLink?.ArticleName
                              : e.ArticleName}
                          </Text>
                          <br></br>
                          <Text>
                            Number of units :{" "}
                            {e.NumberOfUnits?.lowerBoundQualifiers}
                            {e.NumberOfUnits?.lowerBoundValue}
                            {e.NumberOfUnits?.upperBoundQualifiers}
                            {e.NumberOfUnits?.upperBoundValue}
                          </Text>
                          <br></br>
                          <Divider />
                        </Col>
                      </>
                    ))}
              </div>
            )}
          </Card>
        </>
      ),
      disabled: currentStep < 3,
    },
  ];

  const tabItems = [
    {
      key: "1",
      label: (
        <>
          Safe Use Instructions
          <span style={{ marginLeft: "8px" }}>
            {instructionIcon === true ? (
              <ExclamationCircleFilled
                style={{ color: "#F5222D", fontSize: "16px" }}
              />
            ) : instructionIcon === false ? (
              <CheckCircleFilled
                style={{ color: "#52C41A", fontSize: "16px" }}
              />
            ) : (
              ""
            )}
          </span>
        </>
      ),
      children: (
        <>
          <Text type="secondary">
            Safe use instructions <span style={{ color: "red" }}>*</span>
          </Text>
          <Row gutter={24} style={{ marginTop: "1rem" }}>
            <Col span={24} sm={24} md={24}>
              <Form.Item
                name="NoSafeUseInstructions"
                valuePropName="checked"
                rules={[
                  {
                    validator(_, value) {
                      if (
                        !value &&
                        !form.getFieldValue("SafeUseInstructions")
                      ) {
                        return Promise.reject(
                          new Error(
                            "Either the checkbox must be checked or the Add instrutions must be filled."
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Checkbox onChange={onChangeCheck}>
                  The identification of the Candidate List substance is
                  sufficient to allow safe use of the article throughout the
                  whole life cycle including service life, disassembly and
                  waste/recycling stage
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} style={{ marginTop: "1rem" }}>
            <Col span={24} sm={24} md={24}>
              <Form.List
                name="SafeUseInstructions"
                rules={[
                  {
                    validator: async (_, value) => {
                      if (
                        value?.length === 0 &&
                        !form.getFieldValue("NoSafeUseInstructions")
                      ) {
                        return Promise.reject(
                          new Error(
                            "Either the instructions field must be filled or the checkbox must be checked."
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map(({ key, name }) => (
                      <>
                        <Row gutter={[24, 24]}>
                          <Col span={23} sm={23} md={23}>
                            <Form.Item key={key} style={{ marginBottom: 8 }}>
                              <Form.Item
                                name={[name]}
                                rules={[
                                  {
                                    validator: async (_, value) => {
                                      if (!value) {
                                        return Promise.reject(
                                          new Error(
                                            "Instructions cannot be empty"
                                          )
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  },
                                ]}
                              >
                                <TextArea
                                  rows={4}
                                  placeholder="Safe Use Instructions"
                                />
                              </Form.Item>
                            </Form.Item>
                          </Col>
                          <Col span={1} sm={1} md={1}>
                            <DeleteOutlined onClick={() => remove(name)} />
                          </Col>
                        </Row>
                      </>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                        disabled={checked}
                      >
                        Add Instruction
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        </>
      ),
      disabled: currentStep < 1,
    },
    {
      key: "2",
      label: (
        <>
          Complex Object Component
          <span style={{ marginLeft: "8px" }}>
            {complexIcon === true ? (
              <ExclamationCircleFilled
                style={{ color: "#F5222D", fontSize: "16px" }}
              />
            ) : complexIcon === false ? (
              <CheckCircleFilled
                style={{ color: "#52C41A", fontSize: "16px" }}
              />
            ) : (
              ""
            )}
          </span>
        </>
      ),
      children: (
        <>
          <Row gutter={24} style={{ marginTop: "1rem" }}>
            <Col span={24} sm={24} md={24}>
              <Form.List
                name="ComplexObjectComponents"
                rules={[
                  {
                    validator: async (_, value) => {
                      if (!value || value?.length < 1) {
                        return Promise.reject(
                          new Error(
                            "At least 1 Complex Object Components required"
                          )
                        );
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field) => (
                      <>
                        <Card
                          type="inner"
                          size="small"
                          title={`Item ${field.name + 1}`}
                          key={field.key + 1}
                        >
                          <Row gutter={[24, 24]}>
                            <Col span={4} sm={4} md={4}>
                              <Form.Item
                                name={[field.name, "manufacturer", "name"]}
                                label="Manufacturer Name"
                              >
                                <Input disabled />
                              </Form.Item>
                            </Col>
                            <Col span={4} sm={4} md={4}>
                              <Form.Item
                                name={[
                                  field.name,
                                  "manufacturer",
                                  "itemNumber",
                                ]}
                                label="Manufacturer Item Number"
                              >
                                <Input disabled />
                              </Form.Item>
                            </Col>
                            <Col span={4} sm={4} md={4}>
                              <Form.Item
                                name={[field.name, "ArticleLink"]}
                                label="Dataset Id"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Attached dataset id is not linked! Click on edit to submit the dataset",
                                  },
                                ]}
                              >
                                <Input disabled />
                              </Form.Item>
                            </Col>
                            <Col span={2} sm={2} md={2}>
                              <Form.Item
                                name={[
                                  field.name,
                                  "NumberOfUnits",
                                  "lowerBoundQualifiers",
                                ]}
                                label="Lower Qualifier"
                              >
                                <Select
                                  options={[{ value: ">=", label: ">=" }]}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={2} sm={2} md={2}>
                              <Form.Item
                                name={[
                                  field.name,
                                  "NumberOfUnits",
                                  "lowerBoundValue",
                                ]}
                                label="Lower value"
                              >
                                <InputNumber />
                              </Form.Item>
                            </Col>
                            <Col span={2} sm={2} md={2}>
                              <Form.Item
                                name={[
                                  field.name,
                                  "NumberOfUnits",
                                  "upperBoundQualifiers",
                                ]}
                                label="Upper Qualifier"
                              >
                                <Select
                                  options={[{ value: "<=", label: "<=" }]}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={2} sm={2} md={2}>
                              <Form.Item
                                name={[
                                  field.name,
                                  "NumberOfUnits",
                                  "upperBoundValue",
                                ]}
                                label="Upper value "
                              >
                                <InputNumber />
                              </Form.Item>
                            </Col>
                            <Col
                              span={4}
                              sm={4}
                              md={4}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: "1rem",
                              }}
                            >
                              <Space
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  marginBottom: "1rem",
                                }}
                              >
                                {permissions?.update.includes("scip") && (
                                  <ScipForm
                                    type="edit"
                                    formData={form.getFieldValue([
                                      "ComplexObjectComponents",
                                      field.name,
                                    ])}
                                    fetchData={fetchArticleData}
                                  />
                                )}
                                <Popconfirm
                                  title="Delete Dataset"
                                  description={`Are you sure to delete manufacturer name - ${
                                    scipArticle[field.key]?.manufacturer?.name
                                  } and number - ${
                                    scipArticle[field.key]?.manufacturer
                                      ?.itemNumber
                                  } from the product - ${
                                    scipProductArticle?.name
                                  } ?`}
                                  onConfirm={() => {
                                    remove(field.name);
                                  }}
                                  okText="Yes"
                                  cancelText="No"
                                  placement="topLeft"
                                >
                                  <DeleteOutlined
                                    style={{
                                      marginLeft: "1rem",
                                    }}
                                  />
                                </Popconfirm>
                              </Space>
                            </Col>
                          </Row>
                          {scipProductArticle?.Status &&
                            !form.getFieldValue([
                              "ComplexObjectComponents",
                              field.name,
                              "ComplexObjectComponents",
                            ]) && (
                              <Alert
                                message={`Dataset Id ${form.getFieldValue([
                                  "ComplexObjectComponents",
                                  field.name,
                                  "ArticleLink",
                                ])} was not included in your previous submission!`}
                                type="info"
                                showIcon
                                closable
                              />
                            )}
                        </Card>
                      </>
                    ))}
                    <Form.Item>
                      {/* <Button
                        type="primary"
                        onClick={() => {
                          add();
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Complex Object Component
                      </Button> */}
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        </>
      ),
      disabled: currentStep < 2,
    },
  ];

  const stepsItems = [
    {
      title: "",
    },
    {
      title: "",
    },
  ];

  const addStepItems = [
    {
      title: "",
    },
    {
      title: "",
    },
  ];

  if (primaryArticleIdentifierType !== "66297") {
    items.splice(1, 0, ...tabItems);
    stepsItems.splice(1, 0, ...addStepItems);
  }

  const onClose = () => {
    form.resetFields();
  };

  const handleTabChange = (key: string) => {
    setCurrentStep(parseInt(key));
  };

  const fetchData = (productId: string) => {
    setLoading(true);
    dispatch(readScipProductArticle(productId)).then((response) => {
      setScipProductArticle(response.scipProductArticle);
      form.setFieldValue("name", response.scipProductArticle?.name);
      form.setFieldValue("number", response.scipProductArticle?.number);
      form.setFieldValue(
        "ArticleName",
        response.scipProductArticle?.ArticleName
          ? response.scipProductArticle?.ArticleName
          : response.scipProductArticle?.name
      );
      form.setFieldValue(
        "PrimaryArticleIdentifierValue",
        response.scipProductArticle?.PrimaryArticleIdentifierValue
          ? response.scipProductArticle?.PrimaryArticleIdentifierValue
          : response.scipProductArticle?.number
      );
      form.setFieldValue(
        "PrimaryArticleIdentifierType",
        response.scipProductArticle?.PrimaryArticleIdentifierType?.code
          ? response.scipProductArticle?.PrimaryArticleIdentifierType?.code
          : PrimaryArticleIdentifierTypeList.find(
              (v: any) => v.code === "66306"
            )?.code
      );
      setPrimaryArticleIdentifierType(
        response.scipProductArticle?.PrimaryArticleIdentifierType?.code
      );
      form.setFieldValue(
        "NoSafeUseInstructions",
        response.scipProductArticle?.NoSafeUseInstructions
      );
      form.setFieldValue(
        "ArticleCategory",
        response.scipProductArticle?.ArticleCategory?.map((e: any) => e.code)
      );
      form.setFieldValue(
        "EUProductionFlag",
        response.scipProductArticle?.EUProductionFlag?.code
      );

      const Characteristics = {
        ...response.scipProductArticle?.Characteristics,
        ...(response.scipProductArticle.Characteristics?.HeightUnit && {
          HeightUnit:
            response.scipProductArticle.Characteristics?.HeightUnit?.code,
        }),
        ...(response.scipProductArticle.Characteristics?.WeightUnit && {
          WeightUnit:
            response.scipProductArticle.Characteristics?.WeightUnit?.code,
        }),
        ...(response.scipProductArticle.Characteristics?.DensityUnit && {
          DensityUnit:
            response.scipProductArticle.Characteristics?.DensityUnit?.code,
        }),
        ...(response.scipProductArticle.Characteristics?.VolumeUnit && {
          VolumeUnit:
            response.scipProductArticle.Characteristics?.VolumeUnit?.code,
        }),
        ...(response.scipProductArticle.Characteristics?.Color && {
          Color: response.scipProductArticle.Characteristics?.Color?.code,
        }),
      };
      form.setFieldValue("Characteristics", Characteristics);
      setCurrentStep(parseInt(response.scipProductArticle?.currentStep || 0));
      setLoading(false);
    });
  };

  const validateComplexObject = async () => {
    try {
      const values = form.getFieldValue("ComplexObjectComponents") || [];
      for (let index = 0; index <= values?.length; index++) {
        await form.validateFields([
          ["ComplexObjectComponents", index, "ArticleLink"],
        ]);
      }
    } catch (error: any) {
      if (error) {
        setComplexIcon(true);
      }
    }
  };

  const fetchArticleData = () => {
    setLoading(true);
    dispatch(readScipArticle(productId as string)).then((response) => {
      setScipArticle(response);
      const complexObjectComponents = response?.map((e: any) => ({
        ...e,
        ArticleLink: e.ComplexObjectComponents?.ArticleLink?.uuid
          ? e.ComplexObjectComponents?.ArticleLink?.uuid
          : e.uuid,
        NumberOfUnits: {
          lowerBoundQualifiers:
            e.ComplexObjectComponents?.NumberOfUnits?.lowerBoundQualifiers,
          lowerBoundValue:
            e.ComplexObjectComponents?.NumberOfUnits?.lowerBoundValue,
          upperBoundQualifiers:
            e.ComplexObjectComponents?.NumberOfUnits?.upperBoundQualifiers,
          upperBoundValue:
            e.ComplexObjectComponents?.NumberOfUnits?.upperBoundValue,
        },
      }));
      form.setFieldValue("ComplexObjectComponents", complexObjectComponents);
      setLoading(false);
    });
  };

  const onValuesChange = (value: any) => {
    if (value.version !== undefined) {
      setVersionModified(true);
    }
  };

  useEffect(() => {
    if (productId) {
      fetchData(productId);
      fetchArticleData();
      setComplexIcon("");
      setIdentifiersIcon("");
      setInstructionIcon("");
    }
  }, [productId]);

  useEffect(() => {
    if (scipArticle && scipProductArticle) {
      validateComplexObject();
    }
  }, [scipArticle]);

  return (
    <>
      <Space
        align="end"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <div>
          <Title level={5}>{scipProductArticle?.name}</Title>
          <Text>{scipProductArticle?.number}</Text>
        </div>
        <Space>{actionCards}</Space>
      </Space>
      <Steps
        current={currentStep}
        size="small"
        style={{ maxWidth: 500, margin: "auto", marginBottom: "2rem" }}
        items={stepsItems}
      />
      {loading ? (
        <Spin fullscreen spinning={loading} />
      ) : (
        <>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
            onValuesChange={onValuesChange}
          >
            <Tabs
              defaultActiveKey="0"
              activeKey={currentStep.toString()}
              onChange={handleTabChange}
              items={items}
              tabPosition="top"
            />
          </Form>
        </>
      )}
    </>
  );
};

export default ScipCreate;
