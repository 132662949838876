import React, { FC, useEffect, useState } from "react";
import { Card, Spin, Typography } from "antd";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import { getLocalStorage } from "../../../../utils/localStore";

const { Text } = Typography;

Charts(FusionCharts);

const contentStyle: React.CSSProperties = {
  padding: 50,
  background: "rgba(0, 0, 0, 0.05)",
  borderRadius: 4,
};
const content = <div style={contentStyle} />;

interface Statistic {
  label: string;
  value: number;
  color: string;
}

interface Regulation {
  number: string;
  name: string;
  description: string;
  substances: any[];
  exemptions: any[];
  completedStatus: string;
  compliantStatus: string;
  nonCompliantStatus: string;
  statistics: Statistic[];
}

interface DataSource {
  chart: {
    caption: string;
    yaxisname: string;
    xaxisname: string;
    formatnumberscale: string;
    plottooltext: string;
    theme: string;
    drawcrossline: string;
    showvalues: string;
    stack100percent: string;
    divlinecolor: string;
    divlinealpha: string;
    legendbgcolor: string;
    legendborderalpha: string;
    plotspacepercent: string;
    useRoundEdges?: string;
    plotFillangle?: string;
    isSliced?: string;
    plotFillAlpha?: string;
  };
  categories: { category: { label: string }[] }[];
  dataset: {
    seriesname: string;
    color: string;
    data: { value: string; showvalue?: string }[];
  }[];
}

const ProgressChart: FC<{
  loading: boolean;
  regulation: Regulation[] | null;
  materials: any;
}> = ({ loading, regulation, materials }) => {
  const [dataSource, setDataSource] = useState<DataSource | null>(null);
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const labelOrder = [
    "YES",
    "YES WITH EXEMPTION",
    "NO",
    "OUT OF SCOPE",
    "UNKNOWN",
    "Not Updated",
  ];

  useEffect(() => {
    if (!loading && regulation && regulation.length > 0) {
      const filteredRegulations = regulation.filter(
        (reg) => reg.statistics.length > 0
      );
      const analytics = [
        ...filteredRegulations,
        ...(permissions?.read.includes("materials") && materials?.length > 0
          ? [materials[0]]
          : []),
      ];
      const newDataSource: DataSource = {
        chart: {
          caption: "",
          yaxisname: "",
          xaxisname: "",
          formatnumberscale: "1",
          plottooltext: "<b>$dataValue</b> of $seriesName",
          theme: "fusion",
          drawcrossline: "1",
          showvalues: "1", 
          stack100percent: "1",
          divlinecolor: "#ffffff",
          divlinealpha: "0",
          legendbgcolor: "#ffffff",
          legendborderalpha: "0",
          plotspacepercent: "40",
          useRoundEdges: "0", 
          plotFillangle: "0", 
          isSliced: "0", 
          plotFillAlpha: "80",
        },
        categories: [
          {
            category: analytics.map((reg) => ({ label: reg.name })),
          },
        ],
        dataset: [],
      };

      // Collect all unique labels and their colors
      const uniqueLabels: { [key: string]: string } = {};
      analytics.forEach((reg) => {
        reg.statistics?.forEach((stat : any) => {
          if (!uniqueLabels[stat.label]) {
            uniqueLabels[stat.label] = stat.color || "#F39237";
          }
        });
      });

      const sortedLabels = labelOrder.filter((label) => uniqueLabels[label]);
      sortedLabels.forEach((label) => {
        newDataSource.dataset.push({
          seriesname: label,
          color: uniqueLabels[label],
          data: analytics.map((reg) => {
            const stat = reg.statistics.find((stat: any) => stat.label === label);
            const value = stat ? stat.value.toString() : "0";
            return value === "0" ? { value, showvalue: "0" } : { value };
          }),
        });
      });

      const hasAllZeroValues = (values: number[]) =>
        values.every((value) => value === 0);

      if (Object.keys(uniqueLabels).length === 1) {
        const onlyLabel = Object.keys(uniqueLabels)[0];
        const allValues = analytics.flatMap((reg) =>
          reg.statistics
            .filter((stat: any) => stat.label === onlyLabel)
            .map((stat: any) => stat.value)
        );

        if (hasAllZeroValues(allValues)) {
          newDataSource.dataset.forEach((data) => {
            data.data = data.data.map(() => ({ value: "100" }));
          });
        }
      }

      setDataSource(newDataSource);
    } else {
      setDataSource(null); 
    }
  }, [loading, regulation]);

  useEffect(() => {
    if (dataSource) {
      FusionCharts.ready(() => {
        new FusionCharts({
          type: "stackedbar2d",
          renderAt: "progress-chart-container",
          width: "100%",
          height: (dataSource?.categories[0]?.category?.length || 1) * (dataSource?.categories[0]?.category?.length < 10 ? 60 : 45) + "",
          dataFormat: "json",
          dataSource,
        }).render();
      });
    }
  }, [dataSource]);

  return loading ? (
    <Spin tip="Loading" size="large">
      {content}
    </Spin>
  ) : (
    <Card title="Progress" style={{ width: "100%" }}>
      <div id="progress-chart-container" />
      {dataSource && dataSource.dataset.length === 0 && <Text>No data to display.</Text>}
    </Card>
  );
};

export default ProgressChart;
