import React, { FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Typography, Table, Card, Row, Col, Space, Tag, Tooltip } from "antd";
import {
  PhoneOutlined,
  AppstoreOutlined,
  CheckSquareOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { useAppDispatch } from "../../../../../redux/hooks";
import {
  readCampaignSupplierAnalytics,
  readCampaignData,
  readCampaignManager,
} from "../../../../../redux/actions/CampaignManager/campaignManagerAction";
import { getRegulationsFromLocalStorage } from "../../../../../utils/localStore";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { Campaign } from "../../../../../utils/types/CampaignManager/campaignManager";
import ErrorBoundary from "../../../../../utils/errorBoundary";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

const { Title, Text } = Typography;

interface ChartProps {
  data: any[];
  chart: any;
}

const FusionChart: FC<ChartProps> = ({ data, chart }) => {
  useEffect(() => {
    FusionCharts.ready(function () {
      new FusionCharts({
        type: "column3d",
        width: "100%",
        height: "700",
        dataFormat: "json",
        dataSource: { data, chart: { ...chart, paletteColors: "#045B7C,#708090,#FF8C00,#00BFFF" } },
      }).render("chartRef");
    });
  }, [data, chart]);

  return <div id={"chartRef"} />;
};

const CampaignOverview: React.FC<{
  campaignData: any;
}> = (props) => {
  const { id } = useParams<{ id: any }>();
  const dispatch = useAppDispatch();
  const [supplierAnalytics, setSupplierAnalytics] = useState<any[]>([]);
  const [supplierOverview, setSupplierOverview] = useState<any[]>([]);
  const [campaign, setCampaign] = useState<Campaign>();
  const [campaignData, setCampaignData] = useState<any[]>([]);
  const [campaignListedData, setCampaignListedData] = useState<any[]>([]);
  const [totalComponent, setTotalComponent] = useState<any[]>([]);
  const [campaignName, setCampaignName] = useState<any[]>([]);

  const regulations = getRegulationsFromLocalStorage() ?? [];
  const [loading, setLoading] = useState(false);

  const columns: ColumnsType<any> = [
    {
      title: "Status",
      dataIndex: "_id",
      key: "_id",
      sorter: true,
      width: 150,
    },
    {
      title: "Email address",
      dataIndex: "emailAddress",
      key: "emailAddress",
      render: (value: any, record: any) => {
        const emailAddresses = record.emailAddress.map(
          (e: any) => e?.emailAddress
        );

        if (emailAddresses.length > 1) {
          return (
            <><Text>
                {emailAddresses[0]} 
                </Text>
              <Tooltip
                title={emailAddresses.slice(1).join(", ")}
                overlayInnerStyle={{
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                <Text style={{ color: "	#FF8C00",marginLeft:"5px" }}>
                   +{emailAddresses.length - 1} more
                </Text>
              </Tooltip>
            </>
          );
        }

        return <Text>{emailAddresses[0]}</Text>;
      },
    },
  ];

  const chart = {
    caption: campaignName,
    subcaption: campaignName,
    enablesmartlabels: "1",
    showlabels: "1",
    numbersuffix: " %",
    usedataplotcolorforlabels: "1",
    plottooltext: "$label, <b>$value</b>",
    theme: "fusion",
  };

  const fetchCampaign = () => {
    setLoading(true);
    dispatch(
      readCampaignManager({
        id: id,
      })
    )
      .then((response: any) => {
        const regulationData = response.campaign?.regulations?.map(
          (value: any) =>
            regulations.find(
              (regulation: any) => regulation.regulationNumber === value
            )?.name || value
        );
        if (response.campaign?.materials === "Yes") {
          regulationData.push("Materials");
        }
        if (response.campaign?.conflict === "Yes") {
          regulationData.push("Conflict Minerals");
        }
        if (response.campaign?.extended === "Yes") {
          regulationData.push("Extended Minerals");
        }
        setCampaignName(response?.campaign.name)
        setTotalComponent(response?.totalComponentLength);
        setCampaign({ ...response.campaign, regulations: regulationData });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchData = () => {
    setLoading(true);
    dispatch(
      readCampaignSupplierAnalytics({
        sendgridCampaignId: id,
      })
    )
      .then((response: any) => {
        setSupplierOverview(response?.logs);
        setSupplierAnalytics(response?.document);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchCampaignData = () => {
    setLoading(true);
    dispatch(
      readCampaignData({
        sendgridCampaignId: id,
      })
    )
      .then((response: any) => {
        const mappedData = [];
        for (const [key, value] of Object.entries(response?.graphData)) {
          mappedData.push({ label: key, value: value });
        }
        setCampaignData(mappedData);
        setCampaignListedData(response?.logData);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    fetchCampaign();
    fetchCampaignData();
  }, []);

  const totalContacts = supplierAnalytics.reduce(
    (total, supplier) => total + (supplier.contacts?.length || 0),
    0
  );

  const message = campaign?.message || "";

  const processMessage = (message: string) => {
    // Extract the link using regex
    const linkRegex = /<a href='([^']+)'>([^<]+)<\/a>/;
    const match = message.match(linkRegex);

    // Extracted link and link text
    const extractedLink = match ? match[1] : null;
    const linkText = match ? match[2] : null;

    // Modify the original string by replacing the anchor tag with disabled text
    const modifiedMessage = message.replace(
      linkRegex,
      linkText ? `<span style="color: gray;">${linkText}</span>` : ""
    );

    return { extractedLink, modifiedMessage };
  };

  const { modifiedMessage } = processMessage(message);

  return (
    <>
      <ErrorBoundary>
        <Row
          gutter={[16, 16]}
          style={{ justifyContent: "center", marginBottom: "1rem" }}
        >
          <Col span={6}>
            <Card bordered={false} style={{ textAlign: "center" }}>
              <Row align="middle" justify="center">
                <Col>
                  <SolutionOutlined
                    style={{ marginRight: "10px", fontSize: "18px" }} title="Total Suppliers"
                  />

                  <Text style={{ fontSize: "16px", fontWeight: 500 }}>
                    Total Suppliers
                  </Text>
                </Col>
                <Col style={{ marginLeft: "10px" }}>
                  <Text
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      color: "#1890ff",
                    }}
                  >
                    {supplierAnalytics.length}
                  </Text>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false} style={{ textAlign: "center" }}>
              <Row align="middle" justify="center">
                <Col>
                  <PhoneOutlined
                    style={{ marginRight: "10px", fontSize: "18px" }} title="Total Contacts"
                  />
                  <Text style={{ fontSize: "16px", fontWeight: 500 }}>
                    Total Contacts
                  </Text>
                </Col>
                <Col style={{ marginLeft: "10px" }}>
                  <Text
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      color: "#1890ff",
                    }}
                  >
                    {totalContacts}
                  </Text>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false} style={{ textAlign: "center" }}>
              <Row align="middle" justify="center">
                <Col>
                  <AppstoreOutlined
                    style={{ marginRight: "10px", fontSize: "18px" }} title="Total Components"
                  />
                  <Text style={{ fontSize: "16px", fontWeight: 500 }}>
                    Total Components
                  </Text>
                </Col>
                <Col style={{ marginLeft: "10px" }}>
                  <Text
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      color: "#1890ff",
                    }}
                  >
                    {totalComponent}
                  </Text>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false} style={{ textAlign: "center" }}>
              <Row align="middle" justify="center">
                <Col>
                  <CheckSquareOutlined
                    style={{ marginRight: "10px", fontSize: "18px" }} title="Requested Regulations"
                  />
                  <Text style={{ fontSize: "16px", fontWeight: 500 }}>
                    Requested Regulations
                  </Text>
                </Col>
                <Col style={{ marginLeft: "10px" }}>
                  <Text
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      color: "#1890ff",
                    }}
                  >
                    {campaign?.regulations.length}
                  </Text>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <FusionChart data={campaignData} chart={chart} />
          </Col>
          <Col span={12}>
            <Table
              loading={loading}
              dataSource={campaignListedData}
              scroll={{y: "calc(80vh - 280px)" }}
              columns={columns}
              rowKey="supplierId"
            />
            {campaign && (
              <Card title="Email Copy" style={{ marginTop: "20px" }}>
                <Title level={5}>
                  Requested Data:{" "}
                  {campaign.regulations.map((reg, index) => (
                    <Tag
                      color="orange"
                      key={index}
                      style={{ marginRight: "8px" }}
                    >
                      {reg}
                    </Tag>
                  ))}
                </Title>
                <Title level={5}>Subject: {campaign?.subject}</Title>
                <Title level={5}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: modifiedMessage,
                    }}
                  />
                </Title>
              </Card>
            )}
          </Col>
        </Row>
      </ErrorBoundary>
    </>
  );
};

export default CampaignOverview;
