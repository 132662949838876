import React, { useEffect, useState } from "react";
import { Space, Typography, Input, Table, Tag, Button } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { Export } from "../../../utils/types/export";
import { useAppDispatch } from "../../../redux/hooks";
import { listExportRecords } from "../../../redux/actions/exportDataAction";

import ExportDataForm from "../../../components/forms/ExportDataForm";
import { DownloadOutlined } from "@ant-design/icons";
import { baseUrl } from "../../../utils/apiHelper";
import { getLocalStorage } from "../../../utils/localStore";
import axios from "axios";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";

const { Title, Text } = Typography;
const { Search } = Input;

const ExportData: React.FC = () => {
  const dispatch = useAppDispatch();
  const [exports, setExports] = useState<Export[]>([]);
  const [exportCount, setExportCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Export>>({});
  const [tableFilters, setTableFilters] =
    useState<Record<string, FilterValue | null>>();

  const columns: ColumnsType<Export> = [
    {
      title: "Action",
      key: "_id",
      width: 150,
      render: (_, record: Export) =>
        (record.status === "complete" || record.status === "incomplete") && (
          <Button
            type="text"
            icon={<DownloadOutlined />}
            title="Download"
            onClick={() => onClickDownload(record)}
          />
        ),
    },
    {
      title: "File",
      dataIndex: "filename",
      key: "filename",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (text: string) => {
        let color = "blue";
        if (text === "complete") {
          color = "green";
        } else if (text === "initiated") {
          color = "orange";
        } else if (text === "failed") {
          color = "red";
        }
        return <Tag color={color}>{text.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Module",
      dataIndex: "exportType",
      key: "exportType",
      render: (_, record: Export) => (
        <Text>
          {" "}
          {record.exportTypeData && record.exportType
            ? record.exportType + "-" + record.exportTypeData
            : record.exportType
            ? record.exportType
            : record.exportTypeData}
        </Text>
      ),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, record: any) => (
        <Text>
          {new Date(text).toLocaleString()}
          <br />
          {record.createdBy}
        </Text>
      ),
    },
  ];

  const onClickDownload = (record: any) => {
    axios
      .post(
        baseUrl + "/exportrecords/document/download",
        {
          tenantNumber: getLocalStorage("user").tenantNumber,
          filename: record.filename,
          foldername: record.exportType,
        },
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${getLocalStorage("accessToken")}`,
          },
        }
      )
      .then((response: any) => {
        const blobPDF = new Blob([response.data], {
          type: "text/csv;charset=utf-8;",
        });
        const blobURL = window.URL.createObjectURL(blobPDF);
        const fileLink = document.createElement("a");
        fileLink.href = blobURL;
        fileLink.download = record.filename;
        fileLink.click();
      });
  };

  const fetchData = (payload?: any) => {
    setLoading(true);
    dispatch(
      listExportRecords({
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.column
          ? tableSorter.order === "descend"
            ? false
            : true
          : false,
        filters: payload ? JSON.stringify(payload) : null,
      })
    )
      .then((response) => {
        setExports(response.exports);
        setExportCount(response.exportCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<Export>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Export> | SorterResult<Export>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Export>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      setExports([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
  ]);

  return (
    <>
      <ErrorBoundary>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <div>
            <Title level={5} style={{ marginBottom: 0 }}>
              Exports
            </Title>
          </div>
          <Space>
            <Search
              placeholder="Search using filename or status or module"
              allowClear
              onSearch={(text) =>
                fetchData({
                  softDelete: false,
                  $or: [
                    { filename: { $regex: text, $options: "i" } },
                    { status: { $regex: text, $options: "i" } },
                    { ExportType: { $regex: text, $options: "i" } },
                  ],
                })
              }
              style={{ width: 350 }}
            />
            <ExportDataForm fetchData={fetchData} />
          </Space>
        </Space>
        <Table
          loading={loading}
          dataSource={exports}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={columns}
          rowKey="_id"
          pagination={{
            ...tablePagination,
            total: exportCount,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          onChange={handleTableChange}
        />
      </ErrorBoundary>
    </>
  );
};

export default ExportData;
